<template>
        <b-form>
            <div class="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div class="col-span-8">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 lg:col-span-12">
                            <input-required
                            v-model="value.descricao"
                            :label="$t('sectionPharmaceuticals.description')"
                            :rules="{required:true, max:50}"
                            max="50"
                            />
                        </div>
                        <div class="col-span-12 xl:col-span-6">
                            <input-default
                            v-model="value.comissao"
                            label="Comissão (%)"
                            type="number"
                            mask="##.##"
                            />
                        </div>
                        <div class="col-span-12 xl:col-span-6">
                            <input-required
                            v-model="value.desconto"
                            label="Desconto (%)"
                            type="number"
                            mask="##.##"
                            :rules="{required:true}"
                            />
                        </div>
                        <div
                        class="col-span-12 xl:col-span-6 flex border__top border__bottom"
                        :class="{flex__direction: $mq.notebook,}"
                        >
                            <div
                            class="w-full 2xl:w-20 p-2"
                            :class="{border__bottom: $mq.notebook, border__right: $mq.desktop,}"
                            >
                                <span class="text-red-500 required">Tipo</span>
                            </div>
                            <template>
                                <div class="flex-1 flex flex-col p-2 border__top border__bottom">
                                    <radioButton :options="options" v-model="value.tipo" stacked rules="required"/>
                                </div>
                            </template>
                        </div>
                            <div class="col-span-12 xl:col-span-6">
                                <input-default
                                v-model="value.descontoMaximo"
                                label="Desconto Máximo (%)"
                                type="number"
                                mask="##.##"
                                :disabled="value.tipo == '2' ||
                                 value.tipo == '5'?
                                 false : true"
                                />
                                <div class="col-span-12 xl:col-span-6 mt-3">
                                    <input-default
                                    v-model="value.markup"
                                    label="Markup (%)"
                                    type="number"
                                    mask="##.##"
                                    :disabled="value.tipo == '5'? false : true"
                                    />
                                </div>
                            </div>
                            <div class="col-span-12 xl:col-span-6">
                                <input-default 
                                v-model="value.grupo" 
                                label="Grupo Exp. Custos"
                                max="2"/>
                            </div>
                            <div class="col-span-12 xl:col-span-6">
                                <CheckboxDefault 
                                label="Ativa controle de lotes" 
                                v-model="value.ativaControleLote"
                                :disabled="value.tipo == '2' ||
                                 value.tipo == '5'?
                                 false : true"
                                />

                                <CheckboxDefault 
                                label="Pesagem monitorada grupo" 
                                v-model="value.pesagemMonitoradaGrupo"
                                :disabled="value.tipo == '0'? false : true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
        </b-form>
</template>

<script>
import { BForm } from "bootstrap-vue";

import InputRequired from "@core/components/commons/inputs/InputRequired";
import InputDefault from "@core/components/commons/inputs/InputDefault";
import radioButton from "@core/components/commons/radioButton/radioButton.vue";
import CheckboxDefault from "@/@core/components/commons/checkbox/CheckboxDefault.vue";

export default {
    name: 'GeneralEnsaioInformation',
    components: {
    BForm,
    InputRequired,
    InputDefault,
    radioButton,
    CheckboxDefault
},
    props: {
        value: {
        type: Object,
        }
    },
    data() {
        return {
            typeValue: this.value.tipo,
            options: [
                { text: "Matéria-Prima", value: "0" },
                { text: "Semi-Acabado", value: "1" },
                { text: "Acabado", value: "2" },
                { text: "Embalagem", value: "3" },
                { text: "Cápsula", value:"4" },
                { text: "Drogaria", value: "5" },
                { text: "Homeopatia", value: "6" },
                { text: "Floral", value: "7" },
            ]
        }
    },
    methods: {
        
    }
}
</script>

<style scoped lang="scss">
    .background__color {
      background-color: #efefef;
    }
    
    .flex__direction {
      flex-direction: column;
    }
    
    .border__top {
      border-top: 1px solid rgba(200, 200, 200, 0.5);
    }
    
    .border__right {
      border-right: 1px solid rgba(200, 200, 200, 0.5);
    }

    .border__left {
      border-left: 1px solid rgba(200, 200, 200, 0.5);
    }
    
    .border__bottom {
      border-bottom: 1px solid rgba(200, 200, 200, 0.5);
    }
    
    .button__border__bottom {
      border-bottom: 3px solid black;
    }
</style>
  
<template>
    <section class="w-full">
        <header-register :showButtonView="false">Grupo de Produtos</header-register>
        <main class="w-full">
            <ManageProductsCreate/>
        </main>
        </section>
  </template>
  
<script type="module">

import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageProductsCreate from "@/components/groupProducts/components/create/ManageProductsCreate.vue";

export default {
    name: "ProvidersEdit",
    components: {
    HeaderRegister,
    ManageProductsCreate
},
};

</script>
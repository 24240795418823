<template>
    <ValidationObserver ref="observer" v-slot="{ validate }">
    <b-form @submit.stop.prevent="validate().then(CreateProduct)">
        <section class="-mt-4">
            <b-tabs class="w-full">
                <b-tab title="Geral">
                    <div class="w-full flex flex-col justify-between">
                        <GeneralProductsInformation v-model="viewModel" class="mt-4"/>
                    </div>
                </b-tab>
                <b-tab title="Ensaio">
                    <GeneralEnsaioInformation v-model="viewModel"/>
                </b-tab>
            </b-tabs>
        </section>

        <div class="flex mt-3 pb-8 border__bottom">
            <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
        </div>

        <footer class="w-full flex items-center justify-start gap-5 my-10">
        <Button
            class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
            buttonText="Confirmar"
            type="submit"
        />
        <Button
            class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
            buttonText="Cancelar"
            :onClick="redirectUrl"
        />
        </footer>
        <Modal
        v-if="modalIsOpen"
        :modalIsOpen.sync="modalIsOpen"
        :closeModal="closeModal"
        :modalText="$t('commons.modalTextCreate')"
        />

        <Modal
        v-if="modalError"
        :modalIsOpen.sync="modalError"
        :closeModal="closeModal"
        :modalText="$t('commons.modalErrorCreate')"
        />
        <Modal
        v-if="modalErroSalvar"
        :modalIsOpen.sync="modalErroSalvar"
        :closeModal="closeModal"
        :modalText="$t('commons.modalErroSalvar')"
        />
    </b-form>
</ValidationObserver>
</template>

<script>

import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

import GeneralProductsInformation from "./GeneralProductsInformation.vue";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";
import GeneralEnsaioInformation from "./GeneralEnsaioInformation.vue";

export default {
    name: 'ManageProductsCreate',
    components: {
    BForm,
    ValidationObserver,
    GeneralProductsInformation,
    Button,
    Modal,
    GeneralEnsaioInformation
},
    data() {
        return {
            modalIsOpen: false,
            modalError: false,
            modalErrorMessage: null,
            modalErroSalvar:false,
            viewModel: {
                descricao: null,
                comissao: null,
                desconto: null,
                descontoMaximo: null,
                markup: null,
                grupo: null,
                tipo: null,
                ativaControleLote: false,
                pesagemMonitoradaGrupo: false,
                campoFarmacopeia: [],
            }
        }
    },
    methods: {
        redirectUrl() {
            return this.$router.push(`/groupProducts`);
        },
        openModal() {
            this.modalIsOpen = true;
        },
        closeModal(event) {
            if (event.target === event.currentTarget) {
                this.modalIsOpen = false;
                this.modalError = false;
                this.modalErroSalvar = false;
                window.scrollTo({
                top: 0,
                behavior: "smooth",
                });
            }
        },
       async CreateProduct () { 
            const isValid = await this.$refs.observer.validate();
            console.log(this.viewModel.campoFarmacopeia);
            if (!isValid) {
                    this.modalError = true;
                    return
                }
                this.$http({
                url: "/grupoProduto/novo",
                data: this.viewModel,
                method: "POST"
            }).then(() => {
                this.modalIsOpen = true;
                setTimeout(() => this.$router.push(`/groupProducts`), 3000);
            })
                .catch(() => this.modalErroSalvar = true)
        },
    },
}
</script>

<style scoped>
    .border__bottom {
        border-bottom: 1px solid #5b68735d;
    }
</style>